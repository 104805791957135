/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
import {
  BackgroundCircle, Item, LineLock, StyledImage,
} from './styles';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import HeadphoneJbl from '../../images/fone-jbl.svg';
import Airfryer from '../../images/airfryer.svg';
import AlexaEchoPop from '../../images/Alexa-Echo-Pop-Smart.svg';

const AstraCarousel = ({ active, activeIndex }) => {
  const carouselRef = React.useRef();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 501 },
      items: 2,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 501, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  React.useEffect(() => {
    if (carouselRef.current && typeof active === 'number') {
      const slideIndex = activeIndex === -1 ? 0 : activeIndex;
      carouselRef.current.goToSlide(slideIndex, true);
    }
  }, [active]);

  return (
    <Carousel responsive={responsive} ref={carouselRef} partialVisible>
      <Item>
        <div>
          <h1>SORTEIO 01:</h1>
          <h3>01 Fone Bluetooth</h3>
          <BackgroundCircle
            active={active === 150}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={HeadphoneJbl}
            alt="Fone Bluetooth"
            style={active === 150
              ? { height: '150px', width: '250px' }
              : { height: '130px', width: '200px' }}
            active={active < 150}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 150}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 150 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>SORTEIO 02:</h1>
          <h3>01 Air Fryer</h3>
          <BackgroundCircle active={active === 300}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={Airfryer}
            alt="Air Fryer"
            style={active === 300
              ? { height: '150px', width: '250px' }
              : { height: '110px', width: '200px', marginTop: '-90px' }}
            active={active < 300}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 300}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 300 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>SORTEIO 03:</h1>
          <h3>01 Alexa Echo Pop</h3>
          <BackgroundCircle
            active={active === 450}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={AlexaEchoPop}
            alt="Alexa Echo Pop"
            style={active === 450
              ? { height: '180px', width: '220px' }
              : { height: '110px', width: '190px', marginTop: '-90px' }}
            active={active < 450}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 450}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 450 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
    </Carousel>
  );
};

AstraCarousel.propTypes = {
  active: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default AstraCarousel;
