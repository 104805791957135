/* eslint-disable no-console */
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Checkbox, TextField, FormControlLabel,
  Link,
  ThemeProvider, createTheme,
} from '@material-ui/core';
import { PatternFormat } from 'react-number-format';
import axios from 'axios';
import PropTypes from 'prop-types';
import { osName, browserName } from 'react-device-detect';
import toast, { Toaster } from 'react-hot-toast';
import { getAppToken } from 'services/auth';
import { Helpers, Validator } from '@ali-libs/validator';
import { parse } from 'date-fns';
import { FormContainer, Button, ButtonContainer } from './styles';

const localTheme = createTheme({
  typography: {
    htmlFontSize: 16,
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: '16px',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '16px',
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'baseline',
      },
      label: {
        fontSize: '12px',
        textAlign: 'left',
      },
    },
    MuiLink: {
      root: {
        fontSize: '12px',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#3f51b5',
      },
      colorPrimary: {
        '&$checked': {
          color: '#3f51b5',
        },
      },
    },
  },
});

export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export function removeNonNumeric(str) {
  return str?.replace(/\D/g, '');
}

const schema = {
  name: Validator.Yup.string().required().isFullNameValid(),
  phoneNumber: Validator.Yup.string().required().isCellphoneValid(),
  email: Validator.Yup.string().required().email(),
  birthDate: Validator.Yup.date().required().isMinor(),
  nationalDocument: Validator.Yup.string().required().isCPFValid(),
};

export const validationSchema = Helpers.createSchemaYup(schema);

function SignupForm({ company, origin }) {
  const [firstChecked, setFirstChecked] = React.useState(false);
  const [secondChecked, setSecondChecked] = React.useState(false);
  const [ip, setIP] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!firstChecked || !secondChecked) {
      return;
    }
    const formData = new FormData(event.target);
    const data = {
      name: formData.get('fullName').trim(),
      phoneNumber: `+${removeNonNumeric(formData.get('phoneNumber'))}`,
      email: formData.get('email').trim(),
      birthDate: parse(formData.get('birthDate'), 'dd/MM/yyyy', new Date()),
      nationalDocument: removeNonNumeric(formData.get('nationalDocument')),
      landingPageId: company,
      optIn: true,
      optInDate: getCurrentDate(),
      ip,
      system: osName ?? 'N/I',
      browser: browserName,
    };

    try {
      const token = await getAppToken(origin);
      await validationSchema.validate(data, { abortEarly: false });
      await axios.post(`${process.env.REACT_APP_BACKEND}oauth/register/landing-page`, data, { headers: { Authorization: `Bearer ${token}` } });

      toast.success('Usuário cadastrado com sucesso');
      setLoading(false);
    } catch (error) {
      if (error.name === 'ValidationError') {
        error.inner.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIP(response.data.ip);
      } catch (error) {
        console.error('Error fetching the IP:', error);
      }
    };

    fetchIP();
  }, []);

  const handleFirstCheckboxChange = (event) => {
    setFirstChecked(event.target.checked);
  };

  const handleSecondCheckboxChange = (event) => {
    setSecondChecked(event.target.checked);
  };

  return (
    <ThemeProvider theme={localTheme}>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              label="Nome Completo"
              id="fullName"
              name="fullName"
              variant="outlined"
              fullWidth
              required
            />
          </div>
          <div>
            <PatternFormat
              label="Celular com WhatsApp"
              id="phoneNumber"
              name="phoneNumber"
              customInput={TextField}
              format="+55 (##) #####-####"
              mask="_"
              variant="outlined"
              inputMode="decimal"
              type="tel"
              fullWidth
              required
            />
          </div>
          <div>
            <TextField
              label="Email"
              id="email"
              name="email"
              type="email"
              variant="outlined"
              fullWidth
              required
            />
          </div>
          <div>
            <PatternFormat
              label="Data de Nascimento"
              id="birthDate"
              name="birthDate"
              customInput={TextField}
              format="##/##/####"
              mask="_"
              variant="outlined"
              inputMode="decimal"
              type="tel"
              fullWidth
              required
            />
          </div>
          <div>
            <PatternFormat
              label="CPF"
              id="nationalDocument"
              name="nationalDocument"
              customInput={TextField}
              format="###.###.###-##"
              mask="_"
              variant="outlined"
              inputMode="decimal"
              type="tel"
              fullWidth
              required
            />
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={firstChecked}
                onChange={handleFirstCheckboxChange}
                color="primary"
                required
              />
            )}
            label={(
              <>
                Li e concordo com a
                {' '}
                <Link href="https://www.btgpactual.com/termos-e-politicas" target="_blank">
                  política de privacidade
                </Link>
              </>
            )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={secondChecked}
                onChange={handleSecondCheckboxChange}
                color="primary"
                required
              />
            )}
            label={(
              <>
                Estou de acordo com a consulta de minhas informações no Sistema Nacional de
                Crédito, conforme
                {' '}
                <Link href="https://acesso.ali.com.vc/terms" target="_blank">
                  termos de uso
                </Link>
              </>
            )}
          />
          <ButtonContainer>
            <Button type="submit" disabled={loading}>
              Inscrever-se
            </Button>
          </ButtonContainer>
        </form>
        <Toaster />
      </FormContainer>
    </ThemeProvider>
  );
}

SignupForm.propTypes = {
  company: PropTypes.number.isRequired,
  origin: PropTypes.string.isRequired,
};

export default withRouter(SignupForm);
