import React from 'react';
import { Link } from 'react-router-dom';

import { CntFooter } from './styles';

import LogoFooter from '../../images/logo_ali_footer.svg';
import LoginIcon from '../../images/icon_login.svg';
import IgIcon from '../../images/icon_ig.svg';
import youtubeIcon from '../../images/icon_youtube.svg';
import linkedIcon from '../../images/icon_linkedin.svg';
import LockIcon from '../../images/icon_lock.svg';
import LogoInsanyDesign from '../../images/logo_insany_design.svg';
import iconChevronTop from '../../images/icon_chevron_go_top.svg';

const Footer = () => (
  <CntFooter>
    <div className="top">
      <div className="container" id="footer">
        <div className="logo">
          <img src={LogoFooter} alt="Logo Ali Crédito" />
          <div className="slogan">LIBERDADE FINANCEIRA, DE VERDADE.</div>
        </div>
        <div className="btns">
          <a href={`${process.env.REACT_APP_WEBAPP_URL}`} target="_blank" rel="noreferrer" className="login">
            <div className="icon">
              <img src={LoginIcon} alt="" />
            </div>
            <span>Fazer login</span>
          </a>
          <a href={`${process.env.REACT_APP_WEBAPP_URL}`} target="_blank" rel="noreferrer" className="account">
            Criar conta
          </a>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="mid">
        <div className="links">
          <div className="t-link">Ali é para todos</div>
          <Link to="/para-empresas" className="link">Para empresas</Link>
          <Link to="/para-colaboradores" className="link">Para colaboradores</Link>
          <Link to="/sobre-nos" className="link">Quem somos</Link>
        </div>
        <div className="links">
          <div className="t-link">Páginas úteis</div>
          <Link to="/blog" className="link">Blog Ali</Link>
          <Link to="/duvidas-frequentes" className="link">Dúvidas frequentes</Link>
          <a href="https://ali.gupy.io/" target="_blank" rel="noreferrer" className="link">Trabalhe na Ali</a>
          <Link to="/politica-privacidade" className="link">Política de privacidade</Link>
        </div>
        <div className="links">
          <div className="t-link">Contato</div>
          <div className="link">(11) 4003-9023</div>
          <div className="link">ajuda@alicredito.com.br</div>
          <a href={`${process.env.REACT_APP_WEBAPP_URL}`} target="_blank" rel="noreferrer" className="link">Simule agora o seu crédito</a>
        </div>
        <div className="links cnt-social">
          <div className="t-link">
            Acompanhe nossas redes sociais
          </div>
          <div className="social-media">
            <a href="https://www.linkedin.com/company/alicredito/" target="_blank" rel="noreferrer" aria-label="LinkedIn"><img src={linkedIcon} alt="LinkedIn" /></a>
            <a href="https://www.instagram.com/alicredito/" target="_blank" rel="noreferrer" aria-label="Instagram"><img src={IgIcon} alt="Instagram" /></a>
            <a href="https://www.youtube.com/channel/UCwUZbALBAFG4rEnB2TPk12A" target="_blank" rel="noreferrer" aria-label="YouTube"><img src={youtubeIcon} alt="YouTube" /></a>
          </div>
        </div>
        <div className="anchor">
          <img src={iconChevronTop} alt="" />
          <div
            role="none"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            Ir para o topo
          </div>
        </div>
      </div>
      <div className="bot">
        <div className="safe">
          <div className="box">
            <img src={LockIcon} alt="" />
            SITE 100% SEGURO
          </div>
        </div>
        <div className="cnt-digital">
          <div className="t-digital">Processo fácil e tudo digital</div>
          <div className="text">Nós garantimos uma relação saudável durante toda a sua jornada com a gente, do começo ao fim. Afinal, esse é o nosso compromisso com você: muito mais do que um cliente, você é um parceiro. E nós não vamos medir esforços para melhorar sua vida financeira.</div>
          <div className="text small">
            A
            {' '}
            <b>ali.com.vc</b>
            {' '}
            e
            {' '}
            <b>alicredito.com.br</b>
            {' '}
            pertence à Ali Crédito e Pagamentos (&ldquo;Ali&quot;), CNPJ 29.279.532/0001-91,
            com sede na
            R. Gomes de Carvalho, 1765, 7° andar, Vila Olímpia, São Paulo - SP, 05401-200, Brasil.
            Ali é correspondente bancário do BMP Money Plus Sociedade de Crédito Direto S.A. e
            Banco Cetelem S.A., nos termos da Resolução nº 3.954, de 24 de fevereiro de 2011.
          </div>
        </div>
      </div>
      <div className="links cnt-social-mob">
        <div className="t-link">
          Acompanhe nossas redes sociais
        </div>
        <div className="social-media">
          <a href="/" aria-label="LinkedIn"><img src={linkedIcon} alt="LinkedIn" /></a>
          <a href="/" aria-label="Instagram"><img src={IgIcon} alt="Instagram" /></a>
          <a href="/" aria-label="YouTube"><img src={youtubeIcon} alt="YouTube" /></a>
        </div>
      </div>
    </div>

    <div className="copy">
      <div className="container">
        <div className="copyright">
          Copyright ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          Ali. Todos os direitos reservados
        </div>
        <div className="insany-design">
          Feito por:
          <img src={LogoInsanyDesign} alt="Insany Design" />
        </div>
      </div>
    </div>

  </CntFooter>
);

export default Footer;
