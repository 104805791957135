import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { Footer, SecondFooter } from './styles';
import BtgAli from '../../images/btg_ali.svg';
import Bill from '../../images/bill.svg';
import Whatsapp from '../../images/whatsapp.svg';

function LpFooterBtg() {
  return (
    <>
      <Footer>
        <div>
          <img src={BtgAli} alt="Ali logo" style={{ height: '80px' }} />
          <div>
            <img src={Bill} alt="Ali logo" style={{ height: '40px' }} />
            <Link href="https://vai.ali.com.vc/consignadobtg" target="_blank">
              Acesse o app BTG Pactual Banking
            </Link>
          </div>
          <div>
            <img src={Whatsapp} alt="Ali logo" style={{ height: '40px' }} />
            <Link href="https://wa.me/551140034495" target="_blank">
              Fale conosco no
              {' '}
              <b>WhatsApp: (11) 4003-4495</b>
            </Link>
          </div>
        </div>
        {' '}
        A Ali e BTG Pactual têm o compromisso de proteger e respeitar sua privacidade
        e nós usaremos suas informações pessoais somente para administrar sua conta e
        fornecer os produtos e serviços que você nos solicitou. Ocasionalmente, gostaríamos
        de contatá-lo sobre os nossos produtos e serviços, também sobre outros assuntos que
        possam ser do seu interesse. Você pode cancelar o recebimento dessas comunicações
        quando quiser. Para obter mais informações sobre esse cancelamento, nossas práticas
        de privacidade e nosso compromisso em proteger e respeitar sua privacidade, confira
        nossa política de privacidade. Ao fornecer suas informações nessa página, você
        concorda em permitir que a Ali armazene e processe as informações pessoais enviadas
        acima para fornecer o conteúdo e serviços solicitados.
      </Footer>
      <SecondFooter>
        <h2>
          © 2025 BTG Pactual - CNPJ 30.306.294/0002-26. | © 2025 Ali e Economizômetro® -
          CNPJ 29.279.532/0001-91 | Todos os direitos reservados. Saiba mais em www.ali.com.vc.
        </h2>
      </SecondFooter>
    </>
  );
}

export default withRouter(LpFooterBtg);
