/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import {
  ExpandMoreOutlined,
} from '@material-ui/icons';
import axios from 'axios';
import LpFooter from 'components/lp-footer';
import SignupForm from 'components/signup-form';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { getAppToken } from 'services/auth';
import AstraCarousel from 'components/frigorifico-astra-carousel';
import AlexaEchoPop from '../../images/Alexa-Echo-Pop-Smart.svg';
import AliInCellphone from '../../images/AliInCellphone.png';
import AlivieOBolsoComAli from '../../images/alivie-o-bolso-com-ali.svg';
import AstraPrizes from '../../images/astra_prizes.png';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import ManPlaying from '../../images/man_playing.png';
import HappyWoman from '../../images/woman_happy.svg';
import AstraLogo from '../../images/ali_frigorifico_astra.png';
import HeadphoneJbl from '../../images/fone-jbl.svg';
import Airfryer from '../../images/airfryer.svg';
import {
  Answer,
  ArrowIcon,
  BackgroundCircle,
  BlackDivider,
  Button,
  CampaignProgress,
  CarouselBox,
  Container,
  Content,
  FaqBox,
  FaqContainer,
  FaqItem,
  Footer,
  Header,
  ImageContainer,
  LeftContent,
  LineLock,
  Prize,
  PrizeList,
  PrizeListContent,
  PrizesBox,
  Progress, ProgressItem, ProgressList, Question, Regulation, RegulationBox, RightContent,
  StyledImage,
} from './styles';
import 'react-multi-carousel/lib/styles.css';

const FrigorificoAstraLandingPageContent = () => {
  const { companyMgm } = useParams();
  const [pathData, setPathData] = React.useState(null);
  const [registrationCount, setRegistrationCount] = React.useState(null);
  const [institutionalIsValid, setInstitutionalIsValid] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);

  const stages = [150, 300, 450];

  const calculateStage = (registrationCountFunc) => {
    let currentStage = 0;

    // eslint-disable-next-line max-len
    const nextStage = stages.find((stage) => registrationCountFunc < stage) || stages[stages.length - 1];
    const currentStageIndex = stages.indexOf(nextStage);
    if (nextStage === stages[stages.length - 1] && registrationCountFunc < stages[stages.length - 1]) {
      currentStage = stages[currentStageIndex - 1];
    } else if (currentStageIndex === stages.length - 1) {
      currentStage = stages[currentStageIndex];
    } else if (currentStageIndex > 0) {
      currentStage = stages[currentStageIndex - 1];
    }

    const remainingRegistrations = nextStage - registrationCountFunc;
    return {
      currentStage,
      nextStage,
      remainingRegistrations,
      currentStageIndex,
    };
  };

  const history = useHistory();

  React.useEffect(() => {
    const checkEndpoint = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages/path/${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setPathData(response.data);
          setInstitutionalIsValid(true);
        }
      } catch (error) {
        setInstitutionalIsValid(false);
      }
    };

    checkEndpoint();
  }, [companyMgm]);

  React.useEffect(() => {
    const checkRegistrationCount = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages-subscriptions/count?landingPagePath=${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setRegistrationCount(response.data.validSubscriptions);
        }
      } catch (error) {
        setRegistrationCount(0);
      }
    };

    checkRegistrationCount();
  }, [companyMgm]);

  React.useEffect(() => {
    if (institutionalIsValid === false) {
      history.push('/');
    }
  }, [institutionalIsValid, history]);

  if (institutionalIsValid === null) {
    return false;
  }

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'Preciso contratar o crédito Ali para participar dos sorteios?',
      answer: 'Não. Você pode se inscrever para concorrer aos prêmios normalmente, mesmo que não contrate o crédito Ali. Acesse nosso Economizômetro® e confira como podemos te apoiar na redução de dívidas <a href="https://acesso.ali.com.vc/app/economizometro">www.acesso.ali.com.vc/app/economizometro</a>',
    },
    {
      question: 'Até quando poderei participar e concorrer aos prêmios?',
      answer: 'Para participar e concorrer, você deverá fazer seu cadastro preenchendo o formulário na página <a href="https://www.ali.com.vc/alivie-o-bolso/frigorificoastra">www.ali.com.vc/alivie-o-bolso/frigorificoastra</a> até o dia 21/03.',
    },
    {
      question: 'Meu benefício Ali já está ativado. Posso participar?',
      answer: 'Sim. Basta você se inscrever no formulário da página <a href="https://www.ali.com.vc/alivie-o-bolso/frigorificoastra">www.ali.com.vc/alivie-o-bolso/frigorificoastra</a> e você concorrerá a um dos prêmios desbloqueados.',
    },
    {
      question: 'Como os prêmios são desbloqueados?',
      answer: 'Os prêmios seguirão uma dinâmica de destrave por quantidade mínima de novos cadastros por sorteio. Ao final da ação, para liberar o último sorteio, a soma dos cadastros deverá ser de 450. Assim, ao atingir as metas descritas abaixo, os prêmios serão desbloqueados à medida que cada meta for alcançada. Ou seja, cada sorteio requer um número específico de novos cadastros realizados, seguindo a ordem: <br /><br /> <u>Sorteio 01 (150 cadastros):</u><br />O ganhador leva um Fone JBL <br /><u>Sorteio 02 (300 cadastros):</u> <br />O ganhador leva uma Air fryer <br /><u>Sorteio 03 (450 cadastros):</u><br /> O ganhador leva uma Alexa Echo Pop <br />',
    },
    {
      question: 'Como saberei quem ganhou os prêmios sorteados?',
      answer: 'Os ganhadores de cada sorteio serão divulgados nos canais de comunicação oficiais da sua empresa a cada sorteio.',
    },
    {
      question: 'Quais prêmios e quantos sorteios acontecerão?',
      answer: 'Os prêmios são: Fone JBL, Air Fryer e Alexa Echo Pop. Seguindo esta ordem: <br /><br /> <u>Sorteio 01:</u><br /> O ganhador leva um Fone JBL <br /><u>Sorteio 02:</u> <br / >O ganhador leva uma Air fryer <br /><u>Sorteio 03:</u><br /> O ganhador leva uma Alexa Echo Pop <br />',
    },
    {
      question: 'Meu nome foi sorteado. Quando receberei meus prêmios?',
      answer: 'Os prêmios serão postados em até 20 dias úteis após o último sorteio e a confirmação de endereço completo por parte dos vencedores. Os prêmios serão enviados à unidade onde o colaborador vencedor trabalha.',
    },
  ];

  const stageInfo = calculateStage(registrationCount);

  if (institutionalIsValid) {
    return (
      <Container>
        <Helmet>
          <title>
            Ali |
            {' '}
            {pathData.friendlyName ?? ''}
          </title>
        </Helmet>
        <Header>
          <img src={AstraLogo} alt="Astra logo" style={{ height: '50px' }} />
        </Header>
        <Content>
          <LeftContent>
            <ImageContainer>
              <img src={AlivieOBolsoComAli} alt="Alivie o Bolso" />
            </ImageContainer>
            <h1>Economize e concorra a prêmios com o benefício Ali</h1>
            <p>
              <b>Participe sem se preocupar:</b>
              {' '}
              você concorre e só utiliza o benefício Ali se quiser.
            </p>
          </LeftContent>
          <RightContent>
            <SignupForm company={pathData.id} origin="mgm" />
            <img src={HappyWoman} alt="Mulher contente, comemorando uma conquista enquanto segura um celular" style={{ height: '600px' }} />
          </RightContent>
        </Content>
        <BlackDivider currentGap="20em" currentPaddingLeftMedium="40px" currentPaddingLeftLarge="142px" justifyContent="left">
          <div>
            <h1>
              Faltam apenas
              {' '}
              {stageInfo.remainingRegistrations}
              {' '}
              inscritos
            </h1>
            <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
          </div>
          <div>
            <img src={AstraPrizes} alt="Prêmios" style={{ height: '70px' }} />
          </div>
        </BlackDivider>
        <PrizesBox>
          <h1>Você pode ganhar um desses prêmios incríveis</h1>
          <PrizeList>
            <PrizeListContent>
              <Prize>
                <h1>Fone Bluetooth</h1>
                <img src={HeadphoneJbl} alt="Fone Bluetooth" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Alexa Echo Pop</h1>
                <img src={AlexaEchoPop} alt="Alexa Echo Pop" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Airfryer</h1>
                <img src={Airfryer} alt="Airfryer" style={{ height: '150px', width: '250px' }} />
              </Prize>
            </PrizeListContent>
          </PrizeList>
          <h2>*Imagens meramente ilustrativas</h2>
        </PrizesBox>
        {registrationCount !== null && (
          <CarouselBox>
            <div>
              <h1>Ajude seus amigos a economizar:</h1>
              <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
              {stageInfo.currentStage === 450 ? (
                <h1>
                  Todos os sorteios desbloqueados!
                </h1>
              ) : (
                <>
                  <h1>
                    Faltam apenas
                    {' '}
                    {stageInfo.remainingRegistrations}
                    {' '}
                    inscritos
                  </h1>
                  <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
                </>
              )}
            </div>
            <AstraCarousel active={stageInfo.currentStage} activeIndex={stageInfo.currentStageIndex - 1} />
          </CarouselBox>
        )}
        <CampaignProgress>
          <h1>Ajude seus amigos a economizar:</h1>
          <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
          <Progress>
            {stageInfo.currentStage === 450 ? (
              <h1>
                Todos os sorteios desbloqueados!
              </h1>
            ) : (
              <>
                <h1>
                  Faltam apenas
                  {' '}
                  {stageInfo.remainingRegistrations}
                  {' '}
                  inscritos
                </h1>
                <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
              </>
            )}
            <ProgressList id="carousel">
              <ProgressItem>
                <h1>SORTEIO 01:</h1>
                <h2>01 Fone Bluetooth</h2>
                <br />
                <BackgroundCircle
                  active={stageInfo.currentStage === 150}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={HeadphoneJbl}
                  alt="Fone Bluetooth"
                  style={stageInfo.currentStage === 150
                    ? { height: '180px', width: '220px' }
                    : { height: '130px', width: '150px' }}
                  active={stageInfo.currentStage < 150}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 300}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 300
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>SORTEIO 02:</h1>
                <h2>01 Air Fryer</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 300}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={Airfryer}
                  alt="Air Fryer"
                  style={stageInfo.currentStage === 300
                    ? { height: '180px', width: '180px' }
                    : { height: '135px', width: '120px', margin: '-115px 20px 0 20px' }}
                  active={stageInfo.currentStage < 300}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 450}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 450
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>SORTEIO 03:</h1>
                <h2>01 Alexa Echo Pop</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 450}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={AlexaEchoPop}
                  alt="Alexa Echo Pop"
                  style={stageInfo.currentStage === 450
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '130px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 450}
                />
              </ProgressItem>
              <span style={{ minWidth: '20px' }} />
            </ProgressList>
          </Progress>
        </CampaignProgress>
        <FaqBox>
          <div>
            <img src={ManPlaying} alt="Homem jogando" />
          </div>
          <div>
            <h2>Regras da Campanha</h2>
            <FaqContainer>
              {faqData.map((item, index) => (
                <FaqItem key={item.answer}>
                  <Question onClick={() => toggleFaq(index)}>
                    {item.question}
                    <ArrowIcon isOpen={activeIndex === index}><ExpandMoreOutlined /></ArrowIcon>
                  </Question>
                  <Answer
                    isOpen={activeIndex === index}
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </FaqItem>
              ))}
            </FaqContainer>
          </div>
        </FaqBox>
        <RegulationBox>
          <Regulation>
            <div>
              <h2>Acesse e confira o regulamento completo</h2>
              <Button href=" https://qrco.de/alivie-o-bolso-frigorificoastra" target="_blank">
                Consulte aqui
              </Button>
            </div>
            <img src={AliInCellphone} alt="Mulher segurando o celular e abrindo o app da AliCrédito" />
          </Regulation>
        </RegulationBox>
        <Footer>
          <LpFooter />
        </Footer>
      </Container>
    );
  }

  return null;
};

export default FrigorificoAstraLandingPageContent;
